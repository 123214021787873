import React from 'react';
import { Grid, GridRow, GridCol } from '@naf/grid';
import { CardNavigation, CardSize } from '@naf/cards';
import styled from 'styled-components';
import { NavigationLinksType } from '../../../../../types/navigationLinksType';
import { ExternalLinkType } from '../../../../../types/externalLinkType';
import { CtaInternalLinkType } from '../../../../../types/internalLinkType';
import { useDocumentUrlWithNode } from '../../../hooks/useDocumentUrl';
import { NavCardLink } from '../../styled-link/StyledLink';

type NavigationLinksBlockProps = {
  block: NavigationLinksType;
};

export const InternalLinkCard = ({ link: { referenceComponent, text, _key } }: { link: CtaInternalLinkType }) => {
  const href = useDocumentUrlWithNode(referenceComponent);
  return (
    <GridCol s="12" m="6" l="4" xl="4" key={`${_key}_${text}`}>
      <NavCardLink to={href || '#'}>
        <CardNavigation isInternalLink title={text} size={CardSize.Medium} height="100%" />
      </NavCardLink>
    </GridCol>
  );
};

export const ExternalLinkCard = ({ link: { link, text, _key, blank } }: { link: ExternalLinkType }) => (
  <GridCol s="12" m="6" l="4" xl="4" key={`${_key}_${text}`}>
    <FlexWrapper>
      <CardNavigation
        href={link}
        target={blank ? '_blank' : '_self'}
        title={text || ''}
        size={CardSize.Medium}
        height="100%"
      />
    </FlexWrapper>
  </GridCol>
);

export const NavigationLinksBlock = ({ block }: NavigationLinksBlockProps) => {
  if (block.links?.length > 0) {
    return (
      <Grid className="NavigationLinksBlock">
        <GridRow>
          {block.links.map((item) => {
            switch (item.type) {
              case 'ctaExternalLinkComponent':
                return <ExternalLinkCard key={`${item._key}`} link={item as unknown as ExternalLinkType} />;
              case 'ctaInternalLinkComponent':
                return <InternalLinkCard key={`${item._key}`} link={item as unknown as CtaInternalLinkType} />;
              default:
                return null;
            }
          })}
        </GridRow>
      </Grid>
    );
  }
  return null;
};

const FlexWrapper = styled.div`
  display: flex;
`;
